import { fail, success } from '@helpers/result-helper'
import { cloneDeep, sortBy } from 'lodash'
import toast from '@/services/toasts/index.js'
// eslint-disable-next-line no-unused-vars
import RunningLatePayload from '@/models/booking/runningLatePayload'
// eslint-disable-next-line no-unused-vars
import CandidateBookingOverviewViewModel from '@/models/booking/candidateBookingOverviewViewModel'
import $dayjs from '@/services/date/index.js'
import DurationUnits from '@/constants/date/DurationUnits'
// eslint-disable-next-line no-unused-vars
import DateRange from '@/models/general/dateRange'
// eslint-disable-next-line no-unused-vars
import CandidateDateSpecificBookingsSummaryViewModel from '@/models/booking/candidateDateSpecificBookingsSummaryViewModel'
import BookingActionableItemType from '@/constants/booking/BookingActionableItemType'
// eslint-disable-next-line no-unused-vars
import CandidateBookingOverviewWithActionableItemsViewModel from '@/models/booking/candidateBookingOverviewWithActionableItemsViewModel'
// eslint-disable-next-line no-unused-vars
import CandidateBookingOverviewBookingItemViewModel from '@/models/booking/candidateBookingOverviewBookingItemViewModel'
import StoreResourceNotFoundError from '@/models/error/storeResourceNotFoundError'
import DateStripDisplayRange from '@/constants/date/DateStripDisplayRange'
import Duration from '@/models/general/duration'
import {
  calculateLoadRangeBasedWithBuffer,
  determineDateTense,
} from '@/helpers/date-helpers'
import { generateBookingActions } from '@/helpers/booking-helpers'
// eslint-disable-next-line no-unused-vars
import CandidateBookingNavigationViewModel from '@/models/booking/candidateBookingNavigationViewModel'
import CandidateBookingNavigationDTO from '@/models/booking/candidateBookingNavigationDTO'
import {
  checkCacheFreshness,
  getSavedState,
  saveState,
} from '@/helpers/cache-helpers'
// eslint-disable-next-line no-unused-vars
import TentativeFillRequestDTO from '@/models/booking/tentativeFillRequestDTO'
// eslint-disable-next-line no-unused-vars
import TentativeActionResultViewModel from '@/models/booking/tentativeActionResultViewModel'
import TentativeFillAction from '@/constants/booking/TentativeFillAction'
import BookingOverviewBookingIndexDTO from '@/models/booking/bookingOverviewBookingIndexDTO'
// eslint-disable-next-line no-unused-vars
import BookingDetailsGuestAccessRequestDTO from '@/models/booking/bookingDetailsGuestAccessRequestDTO'
// eslint-disable-next-line no-unused-vars
import TentativeFillRequestAnonymousDTO from '@/models/booking/tentativeFillRequestAnonymousDTO'
// eslint-disable-next-line no-unused-vars
import ResultDTO from '@/models/app/resultDTO'
import { isNonEmptyArray } from '@/helpers/array-helpers'
// eslint-disable-next-line no-unused-vars
import CandidateBookingSummaryViewModel from '@/models/booking/candidateBookingSummaryViewModel'
import CandidateBookingSummaryWrapperViewModel from '@/models/booking/CandidateBookingSummaryWrapperViewModel'
import ShiftRecordDetailsViewModel from '@/models/booking/shiftRecord/shiftRecordDetailsViewModel'
// eslint-disable-next-line no-unused-vars
import AddShiftRecordPayloadDTO from '@/models/booking/shiftRecord/addShiftRecordPayloadDTO'
import DateFormatToken from '@/constants/date/DateFormatToken'
import CandidateBookingDetailsViewModel from '@/models/booking/candidateBookingDetailsViewModel'
import shiftRecordPostErrorHandler from '@/services/bookings/ShiftRecordPostErrorHandler'
import shiftRecordDidNotHappenErrorHandler from '@/services/bookings/ShiftRecordDidNotHappenErrorHandler'
import shiftRecordLoadErrorHandler from '@/services/bookings/ShiftRecordLoadErrorHandler'
import CandidateClientAgreementsViewModel from '@/models/client/candidateClientAgreementsViewModel'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    loadingCount: 0,
    crudLoadingCount: 0,
    cachedOverviewBookingDateRanges: [], // A list of previously requested date ranges
    overviewBookings: [], // populates the date strip
    summaryBookings: null, // populates the timeline for a specific date
    summaryBookingsDate: null,
    navigationBookings: [],
    loadingOverviewBookingsCount: 0,
    loadingSummaryBookingsCount: 0,
    loadingRunningLateCount: 0,
    loadingNavigationBookingsCount: 0,
    loadingTentativeCount: 0,
    loadingAgreementsCount: 0,
    hideBookingGuestFooter: getSavedState('hideBookingGuestFooter'),
    loadingShiftRecordDetails: 0,
    loadingShiftRecordSubmit: 0,
    loadingShiftRecordDidNotHappen: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  /**
   * @typedef {{
   * loadingCount: Number
   * crudLoadingCount: Number
   * cachedOverviewBookingDateRanges: DateRange[]
   * overviewBookings: CandidateBookingOverviewViewModel[]
   * summaryBookings: CandidateDateSpecificBookingsSummaryViewModel
   * navigationBookings: CandidateBookingNavigationDTO[]
   * loadingOverviewBookingsCount: Number
   * loadingSummaryBookingsCount: Number
   * loadingNavigationBookingsCount: Number
   * loadingTentativeCount: Number
   * loadingAgreementsCount: Number
   * hideBookingGuestFooter: Boolean
   * loadingShiftRecordDetails: Number
   * loadingShiftRecordSubmit: Number
   * loadingShiftRecordDidNotHappen: Number
   * }} BookingState
   * @type {BookingState}
   */
  state,
  getters: {
    moduleName: () => 'bookings',
    /**
     * @param {BookingState} state
     * @returns {CandidateBookingOverviewViewModel[]}
     */
    overviewBookings: (state) => state.overviewBookings,
    /**
     * @param {BookingState} state
     * @returns {CandidateDateSpecificBookingsSummaryViewModel}
     */
    summaryBookings: (state) => state.summaryBookings,
    summaryBookingsDate: (state) => state.summaryBookingsDate,
    /**
     * @param {BookingState} state
     * @returns {Null|CandidateBookingSummaryViewModel}
     */
    getSummaryBookingById: (state) => (bookingId) => {
      if (
        !state.summaryBookings?.bookings ||
        state.summaryBookings.bookings.length === 0
      )
        return null
      return state.summaryBookings.bookings.find(
        (booking) => booking.id === bookingId
      )
    },
    /**
     * @param {BookingState} state
     * @returns {Null|CandidateBookingSummaryViewModel[]}
     */
    getSummaryBookingsByIds: (state) => (bookingIds) => {
      if (
        !state.summaryBookings?.bookings ||
        state.summaryBookings.bookings.length === 0
      )
        return []

      return state.summaryBookings.bookings.filter((booking) =>
        bookingIds.includes(booking.id)
      )
    },
    isOverviewRangeCached: (state) => (dateRange) => {
      return state.cachedOverviewBookingDateRanges.some(
        (cachedDateRange) =>
          dateRange.start.isBetween(
            cachedDateRange.start,
            cachedDateRange.end,
            DurationUnits.DAY,
            '[]'
          ) &&
          dateRange.end.isBetween(
            cachedDateRange.start,
            cachedDateRange.end,
            DurationUnits.DAY,
            '[]'
          )
      )
    },
    cachedOverviewBookingDateRanges: (state) =>
      state.cachedOverviewBookingDateRanges,
    haveAnyOverviewBookingsBeenLoaded: (state) => {
      return (
        state.cachedOverviewBookingDateRanges &&
        state.cachedOverviewBookingDateRanges.length > 0
      )
    },
    /**
     *
     * @param {BookingState} state
     * @returns {CandidateBookingOverviewViewModel[]}
     */
    overviewBookingsWithinRange: (state) => (dateRange) => {
      return state.overviewBookings.filter((booking) =>
        booking.dateLocal.isBetween(dateRange.start, dateRange.end, '[]')
      )
    },
    /**
     * @param {BookingState} state
     * @returns {CandidateBookingOverviewBookingItemViewModel|null}
     */
    getOverviewBookingById: (state) => (bookingId) => {
      if (!state.overviewBookings || state.overviewBookings.length === 0)
        return null

      for (const overviewBookingSpecificDate of state.overviewBookings) {
        // Sanity check, date should have bookings
        if (
          !overviewBookingSpecificDate?.bookings ||
          overviewBookingSpecificDate.bookings.length === 0
        )
          continue

        const bookingFound = overviewBookingSpecificDate.bookings.find(
          (booking) => booking.id === bookingId
        )

        if (bookingFound) return bookingFound
      }
    },
    overviewBookingsWithPendingAgreements: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      /**
       * @type {CandidateBookingOverviewViewModel[]}
       */
      const overviewBookings = cloneDeep(state.overviewBookings)

      if (!overviewBookings || overviewBookings.length === 0)
        return state.overviewBookings

      for (const overviewBookingSpecificDate of overviewBookings) {
        // Sanity check, date should have bookings
        if (
          !overviewBookingSpecificDate?.bookings ||
          overviewBookingSpecificDate.bookings.length === 0
        )
          continue

        for (const booking of overviewBookingSpecificDate.bookings) {
          const bookingClientHasPendingAgreements = rootGetters[
            'clients/doesClientHavePendingAgreements'
          ](booking.clientId)

          if (!bookingClientHasPendingAgreements) continue

          booking.actionableItems.push(
            BookingActionableItemType.agreementsPending
          )

          booking.actionableItems = generateBookingActions(
            booking.actionableItems,
            determineDateTense(overviewBookingSpecificDate.dateLocal)
          )
        }
      }

      return overviewBookings
    },
    /**
     * @param {BookingState} state
     * @returns
     */
    flattenOverviewBookingsList: (state) => {
      if (!state.overviewBookings || state.overviewBookings.length === 0)
        return null

      const unflattenedBookingsList = []

      for (const overviewBookingSpecificDate of state.overviewBookings) {
        // Sanity check, date should have bookings
        if (
          !overviewBookingSpecificDate?.bookings ||
          overviewBookingSpecificDate.bookings.length === 0
        )
          continue

        unflattenedBookingsList.push(overviewBookingSpecificDate.bookings)
      }

      const flatBookingList = unflattenedBookingsList.flat()
      return flatBookingList
    },
    /**
     * @param {BookingState} state
     * @returns {Null|CandidateBookingNavigationDTO}
     */
    getNavigationBookingsDtoById: (state) => (bookingId) => {
      if (!state.navigationBookings || state.navigationBookings.length === 0)
        return null

      return state.navigationBookings.find(
        (booking) => booking.bookingId === bookingId
      )
    },
    /**
     * @param {BookingState} state
     * @returns {Null|CandidateBookingNavigationViewModel}
     */
    getNavigationBookingsById: (state, getters) => (bookingId) => {
      const navBookingsFound = getters.getNavigationBookingsDtoById(bookingId)
      return navBookingsFound ? navBookingsFound.navigationItems : null
    },
    /**
     * Retrieve the indexes to locate a booking via the passed in ID
     * @param {BookingState} state
     * @returns {BookingOverviewBookingIndexDTO}
     */
    findOverviewBookingIndexById: (state) => (bookingId) => {
      if (!state.overviewBookings || state.overviewBookings.length === 0)
        return null

      for (const [
        topLevelIndex,
        overviewBookingSpecificDate,
      ] of state.overviewBookings) {
        // Sanity check, date should have bookings
        if (
          !overviewBookingSpecificDate?.bookings ||
          overviewBookingSpecificDate.bookings.length === 0
        )
          continue

        const bookingFoundIndex =
          overviewBookingSpecificDate.bookings.findIndex(
            (booking) => booking.id === bookingId
          )

        if (bookingFoundIndex >= 0) {
          return new BookingOverviewBookingIndexDTO({
            topLevelIndex,
            bookingIndex: bookingFoundIndex,
          })
        }
      }

      return null
    },
    /**
     * Retrieve the indexes to locate bookings via the passed in IDs
     * @param {BookingState} state
     * @returns {BookingOverviewBookingIndexDTO}
     */
    findOverviewBookingIndexByIds: (state) => (bookingIds) => {
      if (!state.overviewBookings || state.overviewBookings.length === 0)
        return []

      const bookingIndexes = []

      for (const [
        topLevelIndex,
        overviewBookingSpecificDate,
      ] of state.overviewBookings.entries()) {
        // Sanity check, date should have bookings
        if (
          !overviewBookingSpecificDate?.bookings ||
          overviewBookingSpecificDate.bookings.length === 0
        )
          continue

        const bookingFoundIndex =
          overviewBookingSpecificDate.bookings.findIndex((booking) =>
            bookingIds.includes(booking.id)
          )

        if (bookingFoundIndex >= 0) {
          bookingIndexes.push(
            new BookingOverviewBookingIndexDTO({
              topLevelIndex,
              bookingIndex: bookingFoundIndex,
            })
          )
        }
      }

      return bookingIndexes
    },
    hideBookingGuestFooter: (state) => state.hideBookingGuestFooter,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingOverviewBookings: (state) =>
      state.loadingOverviewBookingsCount > 0,
    isLoadingSummaryBookings: (state) => state.loadingSummaryBookingsCount > 0,
    isLoadingRunningLate: (state) => state.loadingRunningLateCount > 0,
    isLoadingNavigationBookings: (state) =>
      state.loadingNavigationBookingsCount > 0,
    isLoadingTentativeFill: (state) => state.loadingTentativeCount > 0,
    isLoadingAgreements: (state) => state.loadingAgreementsCount > 0,
    isLoadingShiftRecordDetails: (state) => state.loadingShiftRecordDetails > 0,
    isLoadingShiftRecordSubmit: (state) => state.loadingShiftRecordSubmit > 0,
    isLoadingDidNotHappenSubmit: (state) =>
      state.loadingShiftRecordDidNotHappen > 0,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    START_LOADING_OVERVIEW_BOOKINGS(state) {
      state.loadingOverviewBookingsCount++
    },
    FINISH_LOADING_OVERVIEW_BOOKINGS(state) {
      state.loadingOverviewBookingsCount--
    },
    START_LOADING_RUNNING_LATE(state) {
      state.loadingRunningLateCount++
    },
    FINISH_LOADING_RUNNING_LATE(state) {
      state.loadingRunningLateCount--
    },
    START_LOADING_NAVIGATION_BOOKINGS(state) {
      state.loadingNavigationBookingsCount++
    },
    FINISH_LOADING_NAVIGATION_BOOKINGS(state) {
      state.loadingNavigationBookingsCount--
    },
    START_LOADING_TENTATIVE(state) {
      state.loadingTentativeCount++
    },
    FINISH_LOADING_TENTATIVE(state) {
      state.loadingTentativeCount--
    },
    START_LOADING_AGREEMENTS(state) {
      state.loadingAgreementsCount++
    },
    FINISH_LOADING_AGREEMENTS(state) {
      state.loadingAgreementsCount--
    },
    START_LOADING_SHIFT_RECORD_DETAILS(state) {
      state.loadingShiftRecordDetails++
    },
    FINISH_LOADING_SHIFT_RECORD_DETAILS(state) {
      state.loadingShiftRecordDetails--
    },
    START_LOADING_SHIFT_RECORD_SUBMIT(state) {
      state.loadingShiftRecordSubmit++
    },
    FINISH_LOADING_SHIFT_RECORD_SUBMIT(state) {
      state.loadingShiftRecordSubmit--
    },
    START_LOADING_DID_NOT_HAPPEN_SUBMIT(state) {
      state.loadingShiftRecordDidNotHappen++
    },
    FINISH_LOADING_DID_NOT_HAPPEN_SUBMIT(state) {
      state.loadingShiftRecordDidNotHappen--
    },
    /**
     * @param {BookingState} state
     * @param {CandidateBookingOverviewViewModel[]} overviewBookingsVM
     */
    INSERT_OVERVIEW_BOOKINGS(state, overviewBookingsVM) {
      let found

      const mappedOverviewBookings = isNonEmptyArray(overviewBookingsVM)
        ? overviewBookingsVM.map(
            (booking) => new CandidateBookingOverviewViewModel(booking)
          )
        : []

      for (const booking of mappedOverviewBookings) {
        booking.dateLocal = $dayjs(booking.dateLocal)
        found = state.overviewBookings.find((x) =>
          x.dateLocal.isSame(booking.dateLocal, DurationUnits.DAY)
        )

        if (!found) {
          state.overviewBookings.push(booking)
        } else {
          Object.assign(found, booking)
        }
      }

      state.overviewBookings = sortBy(state.overviewBookings, ['dateLocal'])
    },
    /**
     * @param {BookingState} state
     * @param {DateRange} dateRange
     */
    CACHE_OVERVIEW_DATERANGE_REQUEST(state, dateRange) {
      const found = state.cachedOverviewBookingDateRanges.find(
        (cachedDateRange) =>
          dateRange.start.isBetween(
            cachedDateRange.start,
            cachedDateRange.end,
            DurationUnits.DAY,
            '[]'
          ) &&
          dateRange.end.isBetween(
            cachedDateRange.start,
            cachedDateRange.end,
            DurationUnits.DAY,
            '[]'
          )
      )

      if (!found) {
        state.cachedOverviewBookingDateRanges.push(dateRange)
      } else {
        Object.assign(found, dateRange)
      }
    },
    START_LOADING_SUMMARY_BOOKINGS(state) {
      state.loadingSummaryBookingsCount++
    },
    FINISH_LOADING_SUMMARY_BOOKINGS(state) {
      state.loadingSummaryBookingsCount--
    },
    INSERT_SUMMARY_BOOKINGS(state, bookings) {
      state.summaryBookings = new CandidateBookingSummaryWrapperViewModel(
        bookings
      )
    },
    INSERT_SUMMARY_BOOKINGS_DATE(state, newValue) {
      state.summaryBookingsDate = newValue
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
    /**
     * @param {BookingState} state
     * @param {RunningLatePayload} payload
     * @param {Boolean} isRunningLate
     */
    SET_RUNNING_LATE_STATUS_FOR_SUMMARY_BOOKING(
      state,
      { payload, isRunningLate }
    ) {
      if (state.summaryBookings?.bookings) {
        const bookingFound = state.summaryBookings.bookings.find(
          (booking) => booking.id === payload.bookingId
        )

        if (!bookingFound)
          throw new StoreResourceNotFoundError({
            resourceName: 'summaryBooking',
          })

        bookingFound.isRunningLate = isRunningLate
      }
    },
    /**
     * @param {BookingState} state
     * @param {CandidateBookingNavigationDTO} payload
     */
    INSERT_NAVIGATION_BOOKING(state, payload) {
      const navBookingFound = state.navigationBookings.find(
        (booking) => booking.bookingId === payload.bookingId
      )

      if (!navBookingFound) {
        state.navigationBookings.push(payload)
      } else {
        Object.assign(navBookingFound, payload)
      }
    },
    /**
     * @param {BookingState} state
     * @param {BookingOverviewBookingIndexDTO[]} bookingIndexesList
     */
    UPDATE_OVERVIEW_BOOKINGS_WITH_ACCEPT_TENTATIVE_FILL_RESPONSE(
      state,
      bookingIndexesList
    ) {
      for (const bookingIndexes of bookingIndexesList) {
        const booking =
          state.overviewBookings[bookingIndexes.topLevelIndex].bookings[
            bookingIndexes.bookingIndex
          ]

        // Sanity check, booking should exist
        if (!booking) throw new StoreResourceNotFoundError('overviewBooking')

        // Remove tentative status
        booking.actionableItems = booking.actionableItems.filter(
          (actionableItem) =>
            actionableItem !== BookingActionableItemType.tentative
        )
      }
    },
    /**
     * @param {BookingState} state
     * @param {BookingOverviewBookingIndexDTO[]} bookingIndexesList
     */
    REMOVE_OVERVIEW_BOOKINGS_BY_INDEX_LIST(state, bookingIndexesList) {
      for (const bookingIndexes of bookingIndexesList) {
        const bookingListToUpdate =
          state.overviewBookings[bookingIndexes.topLevelIndex].bookings

        // Sanity check, booking should exist
        if (!bookingListToUpdate)
          throw new StoreResourceNotFoundError('overviewBooking')

        // Remove booking from list
        bookingListToUpdate.splice(bookingIndexes.bookingIndex, 1)
      }
    },
    /**
     * @param {BookingState} state
     * @param {Number[]} bookingIds
     */
    UPDATE_SUMMARY_BOOKINGS_WITH_ACCEPT_TENTATIVE_FILL_RESPONSE(
      state,
      bookingIds
    ) {
      if (!state.summaryBookings || state.summaryBookings.bookings.length === 0)
        return null

      if (!bookingIds || bookingIds.length === 0) return null

      const bookingsToUpdate = state.summaryBookings.bookings.filter(
        (booking) => bookingIds.includes(booking.id)
      )

      if (!bookingsToUpdate || bookingsToUpdate.length === 0) return 0

      for (const booking of bookingsToUpdate) {
        // Remove tentative status
        booking.actionableItems = booking.actionableItems.filter(
          (actionableItem) =>
            actionableItem !== BookingActionableItemType.tentative
        )
      }
    },
    /**
     * @param {BookingState} state
     * @param {Number[]} bookingIds
     */
    REMOVE_SUMMARY_BOOKINGS_BY_ID_LIST(state, bookingIds) {
      if (!state.summaryBookings || state.summaryBookings.bookings.length === 0)
        return null

      if (!bookingIds || bookingIds.length === 0) return null

      for (const bookingId of bookingIds) {
        const foundIndex = state.summaryBookings.bookings.findIndex(
          (booking) => booking.id === bookingId
        )

        if (foundIndex < 0) continue

        state.summaryBookings.bookings.splice(foundIndex, 1)
      }
    },
    HIDE_BOOKING_GUEST_FOOTER(state) {
      state.hideBookingGuestFooter = true
      saveState('hideBookingGuestFooter', true)
    },
    /**
     * Removes shift record actionable item from an summary booking
     * @param {BookingState} state
     * @param {Number} bookingId
     */
    UPDATE_SUMMARY_BOOKING_AS_SHIFT_RECORD_COMPLETE(state, bookingId) {
      if (!state.summaryBookings || state.summaryBookings.bookings.length === 0)
        return null

      if (!bookingId && bookingId < 0) return null

      const bookingToUpdate = state.summaryBookings.bookings.find(
        (booking) => bookingId === booking.id
      )

      const bookingShouldBeUpdated =
        bookingToUpdate &&
        bookingToUpdate.actionableItems.includes(
          BookingActionableItemType.confirmDetailsRequired
        )

      if (!bookingShouldBeUpdated) return null

      // Remove shift record actionable item
      bookingToUpdate.actionableItems = bookingToUpdate.actionableItems.filter(
        (actionableItem) =>
          actionableItem !== BookingActionableItemType.confirmDetailsRequired
      )

      // Set shift record as submitted
      bookingToUpdate.isShiftRecordSubmitted = true
    },
    /**
     * Removes shift record actionable item from an overview booking
     * @param {BookingState} state
     * @param {CandidateBookingOverviewBookingItemViewModel} overviewBooking
     */
    UPDATE_OVERVIEW_BOOKING_AS_SHIFT_RECORD_COMPLETE(state, overviewBooking) {
      const overviewBookingShouldBeUpdated =
        overviewBooking &&
        overviewBooking.actionableItems.includes(
          BookingActionableItemType.confirmDetailsRequired
        )

      if (!overviewBookingShouldBeUpdated) return

      // Remove shift record actionable item
      overviewBooking.actionableItems = overviewBooking.actionableItems.filter(
        (actionableItem) =>
          actionableItem !== BookingActionableItemType.confirmDetailsRequired
      )
    },
  },
  actions: {
    /**
     * @param {Object} vuex
     * @param {DateRange} dateRange
     * @returns {Promise<ResultDTO>}
     */
    async getOverviewBookingsInRange({ commit, getters, dispatch }, dateRange) {
      if (getters.isOverviewRangeCached(dateRange)) {
        return success({ data: getters.overviewBookingsWithinRange(dateRange) })
      }

      if (getters.isLoadingOverviewBookings) return Promise.resolve(success())

      commit('START_LOADING_OVERVIEW_BOOKINGS')
      try {
        /**
         * @type {{ status: Number, data: CandidateBookingOverviewWithActionableItemsViewModel }}
         */
        const response = await this.$api.bookings.getOverviewBookingsInRange(
          dateRange.start.format('YYYY-MM-DD'),
          dateRange.end.format('YYYY-MM-DD')
        )

        commit('CACHE_OVERVIEW_DATERANGE_REQUEST', dateRange)
        commit('INSERT_OVERVIEW_BOOKINGS', response.data.bookings)
        await dispatch(
          'clients/insertActionableItems',
          response.data.actionableItems.clients,
          {
            root: true,
          }
        )
        await dispatch(
          'availability/insertAvailability',
          response.data.availability,
          {
            root: true,
          }
        )

        return success({ data: getters.overviewBookings })
      } catch (ex) {
        toast.error(this.$i18n.t('bookings.unableToLoadOverviewBookingsError'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_OVERVIEW_BOOKINGS')
      }
    },
    /**
     * @param {*} param0
     * @param {Date} targetDate
     * @returns {Promise<ResultDTO>}
     */
    async getSummaryBookingsByDate({ commit, getters, dispatch }, targetDate) {
      commit('START_LOADING_SUMMARY_BOOKINGS')
      try {
        const response = await this.$api.bookings.getSummaryBookingsByDate(
          targetDate.format('YYYY-MM-DD')
        )

        commit('INSERT_SUMMARY_BOOKINGS', response.data)
        commit('INSERT_SUMMARY_BOOKINGS_DATE', targetDate.format('YYYY-MM-DD'))

        return success({ data: getters.summaryBookings })
      } catch (ex) {
        toast.error(
          this.$i18n.t('bookings.unableToLoadSummaryBookingsError', {
            date: targetDate.format('YYYY-MM-DD'),
          })
        )
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_SUMMARY_BOOKINGS')
      }
    },
    /**
     * @param {{dispatch: Function, commit: Function}} vuexContext
     * @param {Number} bookingId
     * @returns {Promise<ResultDTO>}
     */
    async getBookingById({ commit, dispatch }, bookingId) {
      commit('START_LOADING')
      try {
        const response = await this.$api.bookings.get(bookingId)
        return success({
          data: new CandidateBookingDetailsViewModel(response.data),
        })
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * @param {*} param0
     * @param {BookingDetailsGuestAccessRequestDTO} payload
     * @returns {Promise<ResultDTO>}
     */
    async getBookingByIdAnon({ commit, dispatch }, payload) {
      commit('START_LOADING')
      try {
        return await this.$api.bookings.getBookingByIdAnon(payload)
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * @param {*} param0
     * @param {Guid} accessToken
     * @returns {Promise<ResultDTO>}
     */
    async getGroupBookingByIdAnon({ commit, dispatch }, accessToken) {
      commit('START_LOADING')
      try {
        return await this.$api.bookings.getGroupBookingByIdAnon(accessToken)
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    setSummaryAvailability({ commit }, newValue) {
      commit('SET_SUMMARY_AVAILABILITY', newValue)
    },
    /**
     * @param {*} param0
     * @param {RunningLatePayload} payload
     * @returns {Promise<ResultDTO>}
     */
    async notifyRunningLate({ commit, dispatch }, payload) {
      commit('START_LOADING_RUNNING_LATE')
      try {
        const response = await this.$api.bookings.notifyRunningLate(
          payload.bookingId,
          payload.minutesLate
        )

        commit('SET_RUNNING_LATE_STATUS_FOR_SUMMARY_BOOKING', {
          payload,
          isRunningLate: true,
        })
        toast.success(this.$i18n.t('bookings.summaryRunningLateSuccessAlert'))
        return response
      } catch (ex) {
        // Still notify the user that the request was a success even if store failed to update
        if (ex instanceof StoreResourceNotFoundError) {
          toast.success(this.$i18n.t('bookings.summaryRunningLateSuccessAlert'))
          return success({ data: ex?.response?.data })
        }

        toast.error(
          this.$i18n.t('error.runningLateAPIFailure', [
            this.$i18n.t('app.companyName'),
          ])
        )

        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_RUNNING_LATE')
      }
    },
    /**
     * Calculates the overview booking load range based on a target date
     * before calling api
     * @param {*} param0
     * @param {Date|String} targetDate Date for the basis of the range calculation
     * @returns {Promise<ResultDTO>}
     */
    async loadOverviewBookingsByTargetDateAsync({ dispatch }, targetDate) {
      const dateRange = calculateLoadRangeBasedWithBuffer(
        DateStripDisplayRange.YEAR,
        targetDate,
        new Duration({ value: 1, unit: DurationUnits.MONTH })
      )

      return await dispatch('getOverviewBookingsInRange', dateRange)
    },
    /**
     * @param {*} param0
     * @param {Number} bookingId
     * @returns {Promise<ResultDTO>}
     */
    async getNavigationBookingsByIdAsync(
      { commit, getters, dispatch },
      bookingId
    ) {
      /**
       * @type {CandidateBookingNavigationDTO}
       */
      const isCached = getters.getNavigationBookingsDtoById(bookingId)

      if (
        isCached &&
        checkCacheFreshness(
          new Duration({ value: 30, unit: DurationUnits.MINUTE }),
          isCached?.lastUpdated
        )
      )
        return success({ data: isCached.navigationItems })
      commit('START_LOADING_NAVIGATION_BOOKINGS')
      try {
        /**
         * @type {{ status: Number, data: CandidateBookingNavigationViewModel }}
         */
        const response = await this.$api.bookings.get(`${bookingId}/navigation`)
        commit(
          'INSERT_NAVIGATION_BOOKING',
          new CandidateBookingNavigationDTO({
            bookingId,
            navigationItems: response.data,
          })
        )
        return success({ data: getters.getNavigationBookingsById(bookingId) })
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_NAVIGATION_BOOKINGS')
      }
    },
    /**
     * @param {*} param0
     * @param {Number} bookingId
     * @returns {Promise<ResultDTO>}
     */
    async getBookingRelatedAgreements(
      { commit, dispatch, getters },
      bookingId
    ) {
      commit('START_LOADING_AGREEMENTS')
      try {
        const response = await this.$api.bookings.getBookingRelatedAgreements(
          bookingId
        )

        const clientAgreements = new CandidateClientAgreementsViewModel(
          response.data
        )

        await dispatch('clients/insertActionableItems', [clientAgreements], {
          root: true,
        })

        return success({ data: clientAgreements })
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_AGREEMENTS')
      }
    },
    /**
     * @param {*} param0
     * @param {TentativeFillRequestDTO} dto
     * @returns {Promise<ResultDTO>}
     */
    async respondToTentativeBookingRequest({ commit, dispatch }, dto) {
      commit('START_LOADING_TENTATIVE')
      try {
        /**
         * @type {{ status: Number, data: TentativeActionResultViewModel }}
         */
        const response = await this.$api.bookings.tentativeFill(dto)

        // If ids aren't returned make sure that at least the target booking id is added
        response.data.bookingIdsActioned = response?.data
          ?.bookingIdsActioned || [dto.bookingId]

        const overviewPromise = dispatch(
          'updateOverviewBookingsAfterTentativeFillAction',
          {
            payload: response?.data,
            action: dto.payload.action,
          }
        )

        const summaryPromise = dispatch(
          'updateSummaryBookingsAfterTentativeFillAction',
          {
            payload: response?.data,
            action: dto.payload.action,
          }
        )

        await Promise.all([overviewPromise, summaryPromise])

        const tentativeGroupCount =
          response?.data?.bookingIdsActioned?.length || 1

        let toastNotification = ''

        switch (dto.payload.action) {
          case TentativeFillAction.accept:
            toastNotification = this.$i18n.tc(
              'bookings.tentativeBookingAcceptSuccessToastNotification',
              tentativeGroupCount,
              { count: tentativeGroupCount }
            )
            break
          case TentativeFillAction.decline:
            toastNotification = this.$i18n.tc(
              'bookings.tentativeBookingDeclineSuccessToastNotification',
              tentativeGroupCount,
              { count: tentativeGroupCount }
            )
            break
        }

        toast.success(toastNotification)
        return response
      } catch (ex) {
        toast.error(
          this.$i18n.t('error.tentativeFillAPIFailure', [
            this.$i18n.t('app.companyName'),
          ])
        )

        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_TENTATIVE')
      }
    },
    /**
     * @param {*} param0
     * @param {TentativeFillRequestAnonymousDTO} dto
     * @returns {Promise<ResultDTO>}
     */
    async respondToTentativeBookingRequestAnon({ commit, dispatch }, dto) {
      commit('START_LOADING_TENTATIVE')
      try {
        /**
         * @type {{ status: Number, data: TentativeActionResultViewModel }}
         */
        return await this.$api.bookings.tentativeFillAnon(dto)
      } catch (ex) {
        toast.error(
          this.$i18n.t('error.tentativeFillAPIFailure', [
            this.$i18n.t('app.companyName'),
          ])
        )

        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_TENTATIVE')
      }
    },
    /**
     * @param {*} param0
     * @param {*} dto
     * @returns {Promise<ResultDTO>}
     */
    async respondToGroupTentativeBookingRequestAnon({ commit, dispatch }, dto) {
      commit('START_LOADING_TENTATIVE')
      try {
        /**
         * @type {{ status: Number }}
         */
        return await this.$api.bookings.groupTentativeFillAnon(dto)
      } catch (ex) {
        toast.error(
          this.$i18n.t('error.tentativeFillAPIFailure', [
            this.$i18n.t('app.companyName'),
          ])
        )

        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_TENTATIVE')
      }
    },
    /**
     * @param {*} param0
     * @param {{ payload: TentativeActionResultViewModel, action: TentativeFillAction}} param1
     */
    async updateOverviewBookingsAfterTentativeFillAction(
      { commit, dispatch, getters },
      { payload, action }
    ) {
      const noBookingsToUpdate =
        !payload.bookingIdsActioned || payload.bookingIdsActioned.length === 0

      if (noBookingsToUpdate) return null

      const overviewBookings = getters.overviewBookings
      if (!overviewBookings || overviewBookings.length === 0) return null

      /**
       * @type {BookingOverviewBookingIndexDTO[]}
       */
      const bookingIndexes = getters.findOverviewBookingIndexByIds(
        payload.bookingIdsActioned
      )

      if (!bookingIndexes || bookingIndexes.length === 0) {
        await dispatch(
          'logException',
          new StoreResourceNotFoundError(
            {
              moduleName: 'overviewBookings',
              message: `Failed to find the indexes for all ${bookingIndexes.length} overview bookings.`,
            },
            { root: true }
          )
        )

        return
      }

      switch (action) {
        case TentativeFillAction.accept:
          commit(
            'UPDATE_OVERVIEW_BOOKINGS_WITH_ACCEPT_TENTATIVE_FILL_RESPONSE',
            bookingIndexes
          )
          break
        case TentativeFillAction.decline:
          commit('REMOVE_OVERVIEW_BOOKINGS_BY_INDEX_LIST', bookingIndexes)
          break
      }
    },
    /**
     * @param {*} param0
     * @param {{ payload: TentativeActionResultViewModel, action: TentativeFillAction}} param1
     */
    updateSummaryBookingsAfterTentativeFillAction(
      { commit },
      { payload, action }
    ) {
      const noBookingsToUpdate =
        !payload.bookingIdsActioned || payload.bookingIdsActioned.length === 0

      if (noBookingsToUpdate) return null

      switch (action) {
        case TentativeFillAction.accept:
          commit(
            'UPDATE_SUMMARY_BOOKINGS_WITH_ACCEPT_TENTATIVE_FILL_RESPONSE',
            payload.bookingIdsActioned
          )
          break
        case TentativeFillAction.decline:
          commit(
            'REMOVE_SUMMARY_BOOKINGS_BY_ID_LIST',
            payload.bookingIdsActioned
          )
          break
      }
    },
    /**
     * Updates a booking to remove the shiftRecord actionable item
     * @param {{commit: Function, dispatch: Function, getters: Object}} vuexContext
     * @param {Number} bookingId
     */
    updateBookingShiftRecordActionableItemToComplete(
      { commit, getters },
      bookingId
    ) {
      const overviewBookingToUpdate = getters.getOverviewBookingById(bookingId)

      commit(
        'UPDATE_OVERVIEW_BOOKING_AS_SHIFT_RECORD_COMPLETE',
        overviewBookingToUpdate
      )

      commit('UPDATE_SUMMARY_BOOKING_AS_SHIFT_RECORD_COMPLETE', bookingId)
    },
    /**
     * Removes a specified booking that the candidate stated did not happen
     * @param {{commit: Function, dispatch: Function, getters: Object}} vuexContext
     * @param {Number} bookingId
     */
    removeBookingThatDidNotHappen({ commit, getters }, bookingId) {
      const overviewBookings = getters.overviewBookings
      if (!overviewBookings || overviewBookings.length === 0) return null

      /**
       * @type {BookingOverviewBookingIndexDTO[]}
       */
      const overviewBookingIndexes = getters.findOverviewBookingIndexByIds([
        bookingId,
      ])

      if (overviewBookingIndexes) {
        commit('REMOVE_OVERVIEW_BOOKINGS_BY_INDEX_LIST', overviewBookingIndexes)
      }

      commit('REMOVE_SUMMARY_BOOKINGS_BY_ID_LIST', [bookingId])
    },
    /**
     * Returns shift record info for a booking.
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} bookingId
     * @returns {Promise<ResultDTO>}
     */
    async loadBookingShiftRecordDetails({ commit, dispatch }, bookingId) {
      commit('START_LOADING_SHIFT_RECORD_DETAILS')
      try {
        const response = await this.$api.bookings.getShiftRecordDetails(
          bookingId
        )

        const shiftRecordVM = new ShiftRecordDetailsViewModel(response.data)

        if (shiftRecordVM.isSubmitted)
          await dispatch(
            'updateBookingShiftRecordActionableItemToComplete',
            bookingId
          )

        return success({ data: shiftRecordVM })
      } catch (ex) {
        await dispatch('logException', ex, { root: true })

        const errorPayload = shiftRecordLoadErrorHandler(
          ex.response,
          this.$i18n
        )

        return fail({ error: errorPayload })
      } finally {
        commit('FINISH_LOADING_SHIFT_RECORD_DETAILS')
      }
    },
    /**
     * Submits a shift record for a booking
     * @param {{dispatch: Function, commit: Function}} vuexContext
     * @param {AddShiftRecordPayloadDTO} payload
     * @returns {Promise<ResultDTO>}
     */
    async submitShiftRecord({ dispatch, commit }, payload) {
      commit('START_LOADING_SHIFT_RECORD_SUBMIT')
      try {
        await this.$api.bookings.submitShiftRecord(payload)

        await dispatch(
          'updateBookingShiftRecordActionableItemToComplete',
          payload.bookingId
        )

        toast.success(
          this.$i18n.t('bookings.shiftRecord.submit.success', [
            $dayjs(payload.date).format(DateFormatToken.ddddLL),
          ])
        )

        return success({})
      } catch (ex) {
        await dispatch('logException', ex, { root: true })

        const errorPayload = shiftRecordPostErrorHandler(
          ex.response,
          this.$i18n
        )

        return fail({ error: errorPayload })
      } finally {
        commit('FINISH_LOADING_SHIFT_RECORD_SUBMIT')
      }
    },
    /**
     * Submits a shift record for a booking
     * @param {{dispatch: Function, commit: Function}} vuexContext
     * @param {Number} bookingId
     * @returns {Promise<ResultDTO>}
     */
    async submitBookingDidNotHappen({ dispatch, commit }, bookingId) {
      commit('START_LOADING_DID_NOT_HAPPEN_SUBMIT')
      try {
        await this.$api.bookings.submitBookingDidNotHappen(bookingId)

        await dispatch('removeBookingThatDidNotHappen', bookingId)

        toast.success(
          this.$i18n.t('bookings.shiftRecord.didNotHappenSuccessText')
        )

        return success({})
      } catch (ex) {
        await dispatch('logException', ex, { root: true })

        const errorPayload = shiftRecordDidNotHappenErrorHandler(
          ex.response,
          this.$i18n
        )

        return fail({ error: errorPayload })
      } finally {
        commit('FINISH_LOADING_DID_NOT_HAPPEN_SUBMIT')
      }
    },
    hideBookingGuestFooter({ commit }) {
      commit('HIDE_BOOKING_GUEST_FOOTER')
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
