import { clone } from 'lodash'
import BookingActionableItemType from '@/constants/booking/BookingActionableItemType'
import DateTense from '@/constants/date/DateTense'
import DurationUnits from '@/constants/date/DurationUnits'
import $dayjs from '@/services/date'
import VuetifyColourClass from '@/constants/core/VuetifyColourClass'
import bookingFeatureFactory from '@/services/features/bookingFeatureFactory'

/**
 * Determines if booking is about to start or is has recently started. Used to display various UI actions (e.g. running late)
 * @param {Object} booking
 * @param {DateTense} tense
 * @returns {Boolean}
 */
const determineIfBookingIsAboutToStart = (booking, tense) => {
  if (tense === DateTense.future || tense === DateTense.past) return false

  const hasBookingEndTimePassed = $dayjs().isAfter($dayjs(booking.endTimeLocal))
  if (hasBookingEndTimePassed) return false

  const MINUTES_BEFORE_START_TIME = 180 // 3 hours
  const MINUTES_AFTER_START_TIME = 60 // 1 hour

  const isBookingStartTimeApproaching = $dayjs().isBefore(
    $dayjs(booking.startTimeLocal)
  )

  const isBookingStartTimeWithinStartHourRangeFromNow =
    isBookingStartTimeApproaching &&
    $dayjs(booking.startTimeLocal).diff($dayjs(), DurationUnits.MINUTE) <=
      MINUTES_BEFORE_START_TIME

  const isAfterBookingStartTime = $dayjs().isAfter(
    $dayjs(booking.startTimeLocal)
  )

  const hasBookingCommencedWithinStartTimeAfterRange =
    isAfterBookingStartTime &&
    $dayjs().diff($dayjs(booking.startTimeLocal), DurationUnits.MINUTE) <=
      MINUTES_AFTER_START_TIME

  return (
    isBookingStartTimeWithinStartHourRangeFromNow ||
    hasBookingCommencedWithinStartTimeAfterRange
  )
}

/**
 * Compiles a list of actionable items, cloning them so the original list isn't modified.
 * If the booking is about to start, additional action items are added
 * @param {BookingActionableItemType[]} actionableItems
 * @param {DateTense} tense
 * @param {Boolean} isBookingAboutToStart Describes if the booking is within the start period time window
 * @returns {BookingActionableItemType[]}
 */
const generateBookingActions = (
  actionableItems,
  tense,
  isBookingAboutToStart = false,
  featureDecisions
) => {
  let processedActionableItems = clone(actionableItems)

  const isTentativeBooking = processedActionableItems.includes(
    BookingActionableItemType.tentative
  )
  const needsToSubmitShiftRecord = processedActionableItems.includes(
    BookingActionableItemType.confirmDetailsRequired
  )
  const hasFeedbackRequired = processedActionableItems.includes(
    BookingActionableItemType.feedbackRequired
  )

  if (isTentativeBooking) return [BookingActionableItemType.tentative]

  switch (tense) {
    case DateTense.future:
      // DateTense.future is a valid tense but does not need any
      // additional rules applied
      break
    case DateTense.present:
      if (isBookingAboutToStart) {
        const featureToggles = bookingFeatureFactory(featureDecisions)
        if (featureToggles.isRunningLateEnabled) {
          processedActionableItems.push(BookingActionableItemType.runningLate)
        }
        processedActionableItems.push(BookingActionableItemType.getDirections)
      }
      break
    case DateTense.past:
      if (needsToSubmitShiftRecord) {
        processedActionableItems = processedActionableItems.filter(
          (actionableItem) =>
            actionableItem ===
              BookingActionableItemType.confirmDetailsRequired ||
            actionableItem === BookingActionableItemType.agreementsPending
        )
      } else if (hasFeedbackRequired) {
        processedActionableItems = processedActionableItems.filter(
          (actionableItem) =>
            actionableItem === BookingActionableItemType.feedbackRequired ||
            actionableItem === BookingActionableItemType.agreementsPending
        )
      }

      break
    default:
      throw new TypeError(`Invalid tense provided: ${tense}`)
  }

  return processedActionableItems
}

/**
 * Filters out actionable items not considered urgent enough to theme various
 * booking components as 'requiring action'.
 * @param {BookingActionableItemType[]} actionableItems
 * @returns {BookingActionableItemType[]}
 */
const filterActionableItemsForProcessingEventColour = (
  actionableItems = []
) => {
  if (!actionableItems && actionableItems.length === 0) return []

  // List of items we want to notify the user that require action
  const includeList = [
    BookingActionableItemType.tentative,
    BookingActionableItemType.agreementsPending,
    BookingActionableItemType.confirmDetailsRequired,
  ]

  return actionableItems.filter((actionableItem) =>
    includeList.includes(actionableItem)
  )
}

/**
 * Determines the accent colour of the booking based on if there are any actionable items
 * @param {BookingActionableItemType[]} actionableItems
 * @returns
 */
const determineColorByBookingActionableItems = (actionableItems = []) => {
  const filteredActionableItems =
    filterActionableItemsForProcessingEventColour(actionableItems)

  return filteredActionableItems.length > 0
    ? VuetifyColourClass.red
    : VuetifyColourClass.blue
}

export {
  generateBookingActions,
  determineIfBookingIsAboutToStart,
  determineColorByBookingActionableItems,
}
