/**
 * Generate feature toggles object for bookings components/logic
 * @param {Object} featureDecisions Object returned from `services/features/featureDecisions.js`
 */
const bookingFeatureFactory = (featureDecisions) => {
  return {
    isRunningLateEnabled: featureDecisions.isRunningLateEnabled(),
  }
}

export default bookingFeatureFactory
