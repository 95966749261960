import { getType } from 'mime'
import { isNonEmptyArray } from '@/helpers/array-helpers'
import { fail, success } from '@/helpers/result-helper.js'
// eslint-disable-next-line no-unused-vars
import DocumentComplianceItemStatusUpdateDto from '@/models/registration/documentComplianceItemStatusUpdateDto'
import RegistrationSummaryViewModel from '@/models/registration/registrationSummaryViewModel'
import RegistrationViewModel from '@/models/registration/registrationViewModel'
import dayjs from '@/services/date/index.js'
import { isCacheFresh } from '@/helpers/cache-helpers'
import DurationUnits from '@/constants/date/DurationUnits.js'
import ComplianceItemVideoViewModel from '@/models/registration/complianceItemVideoViewModel'
// eslint-disable-next-line no-unused-vars
import ComplianceItemStatusUpdateDto from '@/models/registration/complianceItemStatusUpdateDto'
import { extractFileNameFromContentDisposition } from '@/helpers/file-helpers'
import HttpHeaders from '@/constants/api/HttpHeaders'
import DownloadedFile from '@/models/file/downloadedFile'
import ComplianceItemType from '@/constants/registrations/ComplianceItemType'
import ComplianceItemStatus from '@/constants/registrations/ComplianceItemStatus'
import ComplianceItemFormViewModel from '@/models/registration/complianceItemFormViewModel'
import HttpStatusCodes from '@/constants/api/HttpStatusCodes'

const getDefaultState = () => {
  return {
    registrations: null,
    registration: null,
    loadingCount: 0,
    crudLoadingCount: 0,
    loadingVideoDataCount: 0,
    loadingVerifyingComplianceItem: 0,
    loadingFileDataCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'registrations',
    registrations: (state) => state.registrations?.list,
    registration: (state) => state.registration,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingVideoData: (state) => state.loadingVideoDataCount > 0,
    isLoadingVerifyingComplianceItem: (state) =>
      state.loadingVerifyingComplianceItem > 0,
    isLoadingComplianceItemFile: (state) => state.loadingFileDataCount > 0,
    isLoadingComplianceItemForm: (state) => state.loadingFormDetailsCount > 0,
  },
  mutations: {
    SET_REGISTRATIONS(state, regos) {
      state.registrations = {
        list: regos,
        lastUpdated: dayjs(),
        isDirty: false,
      }
    },
    SET_REGISTRATION(state, regos) {
      state.registration = regos
    },
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    START_LOADING_VIDEO_DATA(state) {
      state.loadingVideoDataCount++
    },
    FINISH_LOADING_VIDEO_DATA(state) {
      state.loadingVideoDataCount--
    },
    START_LOADING_VERIFYING_COMPLIANCE_ITEM(state) {
      state.loadingVerifyingComplianceItem++
    },
    FINISH_LOADING_VERIFYING_COMPLIANCE_ITEM(state) {
      state.loadingVerifyingComplianceItem--
    },
    START_LOADING_FILE_DATA(state) {
      state.loadingFileDataCount++
    },
    FINISH_LOADING_FILE_DATA(state) {
      state.loadingFileDataCount--
    },
    START_LOADING_FORM_DETAILS(state) {
      state.isLoadingComplianceItemForm++
    },
    FINISH_LOADING_FORM_DETAILS(state) {
      state.isLoadingComplianceItemForm--
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
    /**
     *
     * @param {*} state
     * @param {DocumentComplianceItemStatusUpdateDto} payload
     */
    UPDATE_DOCUMENT_COMPLIANCE_ITEM(state, payload) {
      if (
        !state.registration ||
        !isNonEmptyArray(state.registration?.complianceItems)
      )
        return

      const found = state.registration?.complianceItems.find(
        (complianceItem) =>
          complianceItem.id === payload.complianceItemId &&
          complianceItem.type === ComplianceItemType.document
      )

      if (!found) return

      found.status = payload.status
      found.referenceFileId = payload.referenceFileId
    },
    /**
     *
     * @param {*} state
     * @param {ComplianceItemStatusUpdateDto} payload
     */
    UPDATE_COMPLIANCE_ITEM_STATUS(state, payload) {
      if (
        !state.registration ||
        !isNonEmptyArray(state.registration?.complianceItems)
      )
        return

      const found = state.registration?.complianceItems.find(
        (complianceItem) => complianceItem.id === payload.complianceItemId
      )

      if (!found) return

      found.status = payload.status
    },
    SET_REGISTRATIONS_DIRTY(state) {
      if (!state.registrations) return
      state.registrations.isDirty = true
    },
  },
  actions: {
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Boolean} forceRefresh
     */
    async getRegistrations({ commit, dispatch }, forceRefresh) {
      if (
        isCacheFresh({
          cacheDuration: 1,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.registrations?.lastUpdated,
          forceRefresh,
          isDirty: state.registrations?.isDirty,
        })
      )
        return

      commit('START_LOADING')
      try {
        const result = await this.$api.registrations.get()
        const registrations = result.data?.map(
          (rego) => new RegistrationSummaryViewModel(rego)
        )

        commit('SET_REGISTRATIONS', registrations)
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} registrationId
     * @returns {Promise<ResultDTO>}
     */
    async getRegistrationById({ commit, dispatch }, registrationId) {
      commit('START_LOADING')
      try {
        const result = await this.$api.registrations.get(registrationId)
        result.data = new RegistrationViewModel(result.data)

        commit('SET_REGISTRATION', result.data)

        return result
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {{registrationId: Number, complianceItemId: Number}} payload
     * @returns {Promise<ResultDTO>}
     */
    async getVideoComplianceItemData(
      { commit, dispatch },
      { registrationId, complianceItemId }
    ) {
      commit('START_LOADING_VIDEO_DATA')
      try {
        const result = await this.$api.registrations.getVideoComplianceItemData(
          { registrationId, complianceItemId }
        )
        result.data = new ComplianceItemVideoViewModel(result.data)
        return result
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_VIDEO_DATA')
      }
    },
    /**
     * @param {{commit: Function}} vuexContext
     * @param {DocumentComplianceItemStatusUpdateDto} payload
     */
    updateDocumentComplianceItem({ commit }, payload) {
      commit('UPDATE_DOCUMENT_COMPLIANCE_ITEM', payload)
      commit('SET_REGISTRATIONS_DIRTY')
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {{registrationId: Number, complianceItemId: Number}} payload
     * @returns {Promise<ResultDTO>}
     */
    async verifyComplianceItem(
      { commit, dispatch },
      { registrationId, complianceItemId }
    ) {
      commit('START_LOADING_VERIFYING_COMPLIANCE_ITEM')
      try {
        return await this.$api.registrations.verifyComplianceItem({
          registrationId,
          complianceItemId,
        })
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_VERIFYING_COMPLIANCE_ITEM')
      }
    },
    /**
     * @param {{commit: Function}} vuexContext
     * @param {ComplianceItemStatusUpdateDto} payload
     */
    updateAgreementComplianceItem({ commit }, payload) {
      commit('UPDATE_COMPLIANCE_ITEM_STATUS', payload)
      commit('SET_REGISTRATIONS_DIRTY')
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {{registrationId: Number, complianceItemId: Number}} payload
     * @returns {Promise<ResultDTO>}
     */
    async getComplianceItemFileData(
      { commit, dispatch },
      { registrationId, complianceItemId }
    ) {
      commit('START_LOADING_FILE_DATA')
      try {
        const response =
          await this.$api.registrations.getComplianceItemFileData({
            registrationId,
            complianceItemId,
          })

        const fileName = extractFileNameFromContentDisposition(
          response.headers[HttpHeaders.contentDisposition]
        )

        const contentType = getType(fileName)

        const blob = new Blob([response.data], {
          type: contentType,
        })

        const fileDto = new DownloadedFile({
          id: complianceItemId,
          type: contentType,
          name: fileName,
          file: blob,
          size: blob.size,
        })

        return success({ data: fileDto })
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_FILE_DATA')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {{registrationId: Number, complianceItemId: Number}} payload
     * @returns {Promise<ResultDTO>}
     */
    async getComplianceItemFormDetails(
      { commit, dispatch },
      { registrationId, complianceItemId }
    ) {
      commit('START_LOADING_FORM_DETAILS')
      try {
        const response =
          await this.$api.registrations.getComplianceItemFormDetails({
            registrationId,
            complianceItemId,
          })

        const hasAlreadySubmittedForm =
          response.statusCode === HttpStatusCodes.NoContent

        return success({
          data: hasAlreadySubmittedForm
            ? { isAlreadySubmitted: true }
            : new ComplianceItemFormViewModel(response.data),
        })
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_FORM_DETAILS')
      }
    },
    setFormComplianceItemToSubmitted({ commit }, complianceItemId) {
      commit('UPDATE_COMPLIANCE_ITEM_STATUS', {
        complianceItemId,
        status: ComplianceItemStatus.AwaitingVerification,
      })
      commit('SET_REGISTRATIONS_DIRTY')
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
