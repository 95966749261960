/* eslint-disable no-unused-vars */
import Grade from '../client/grade'
import CandidateBookingDetailsContactDTO from './candidateBookingDetailsContactDTO'
import CandidateBookingDetailsLocationDTO from './candidateBookingDetailsLocationDTO'
import CandidateBookingDetailsPaymentDTO from './candidateBookingDetailsPaymentDTO'
import CandidateBookingDetailsBlockDTO from './candidateBookingDetailsBlockDTO'
import CandidateBookingDetailsTentativeGroup from './candidateBookingDetailsTentativeGroup'
import BookingActionableItemType from '@/constants/booking/BookingActionableItemType'

export default class CandidateBookingDetailsViewModel {
  constructor({
    id,
    startTimeLocal,
    endTimeLocal,
    clientId,
    clientName,
    location,
    grades = [],
    primaryContact,
    payment,
    description = '',
    actionableItems = [],
    blockDetails = null,
    isRunningLate = false,
    tentativeGroupDetails = null,
    isShiftRecordSubmitted = false,
  } = {}) {
    /**
     * @type {Number}
     */
    this.id = id
    /**
     * @type {Date}
     */
    this.startTimeLocal = startTimeLocal
    /**
     * @type {Date}
     */
    this.endTimeLocal = endTimeLocal
    /**
     * @type {Number}
     */
    this.clientId = clientId
    /**
     * @type {String}
     */
    this.clientName = clientName
    /**
     * @type {CandidateBookingDetailsLocationDTO}
     */
    this.location = location
    /**
     * @type {Grade[]}
     */
    this.grades = grades
    /**
     * @type {CandidateBookingDetailsContactDTO}
     */
    this.primaryContact = primaryContact
    /**
     * @type {CandidateBookingDetailsPaymentDTO}
     */
    this.payment = payment
    /**
     * @type {String}
     */
    this.description = description
    /**
     * @type {BookingActionableItemType[]}
     */
    this.actionableItems = actionableItems
    /**
     * @type {CandidateBookingDetailsBlockDTO?}
     */
    this.blockDetails = blockDetails
    /**
     * @type {Boolean}
     */
    this.isRunningLate = isRunningLate
    /**
     * @type {CandidateBookingDetailsTentativeGroup}
     */
    this.tentativeGroupDetails = tentativeGroupDetails
    /**
     * Indicates if a shiftRecord has already been submit for this booking.
     * @type {Boolean}
     */
    this.isShiftRecordSubmitted = isShiftRecordSubmitted
  }
}
