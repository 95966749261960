import BaseLocationAddress from '../../location/baseLocationAddress'
import ShiftRecordBlockWithId from './shiftRecordBlockWithId'
import dayjs from '@/services/date/index'
import { isNonEmptyArray } from '@/helpers/array-helpers'

/**
 * @class
 * @public
 */
export default class ShiftRecordDetailsViewModel {
  constructor({
    bookingDate,
    isSubmitted = false,
    location = {},
    blocks = [],
    isRestBreakCompliant = false,
    bookingStatus,
    showRestBreakComplianceQuestion = true,
  } = {}) {
    /**
     * Date the shift was held on
     * @type {Date}
     */
    this.bookingDate = bookingDate ? dayjs(bookingDate) : null
    /**
     * Indicates if the candidate has submitted a shift record for this booking
     * @type {Boolean}
     */
    this.isSubmitted = isSubmitted
    /**
     * @type {BaseLocationAddress}
     */
    this.location = new BaseLocationAddress(location)
    /**
     * Time blocks to fill out the shiftRecord form
     * @type {Array<ShiftRecordBlockWithId>}
     */
    this.blocks = isNonEmptyArray(blocks)
      ? blocks.map((block) => new ShiftRecordBlockWithId(block))
      : []
    /**
     * @type {Boolean}
     */
    this.isRestBreakCompliant = isRestBreakCompliant
    /**
     * @type {Number}
     * @see {BookingStatus} for valid range of values
     */
    this.bookingStatus = bookingStatus
    /**
     * @type {Boolean}
     */
    this.showRestBreakComplianceQuestion = showRestBreakComplianceQuestion
  }
}
